import React, { useLayoutEffect, useRef } from "react";
import * as monaco from "monaco-editor/esm/vs/editor/edcore.main";
import { getKustoWorker } from "@kusto/monaco-kusto";
import styled from "styled-components";

import schema from "./schemaExport.json";

window.MonacoEnvironment = {
  getWorker(_moduleId, label) {
    if (label === "kusto") {
      return new Worker(new URL("@kusto/monaco-kusto/release/esm/kusto.worker", import.meta.url));
    }
    return new Worker(new URL("monaco-editor/esm/vs/editor/editor.worker", import.meta.url));
  },
};

export const Editor = React.forwardRef((props, editorRef) => {
  const divRef = useRef(null);

  useLayoutEffect(() => {
    let disposed = false;

    monaco.editor.defineTheme("clarity-dark", schema.EditorTheme);

    const editor = monaco.editor.create(divRef.current, {
      value: "SecurityAlert\n| take 10",
      language: "kusto",
      theme: "clarity-dark",
      minimap: {
        enabled: false,
      },
      automaticLayout: true,
      wordWrap: "on",
      wrappingIndent: "tab",
      wrappingStrategy: "advanced",
      wordWrapBreakAfterCharacters: "\t})]?|&,;",
    });

    editorRef.current = editor;

    getKustoWorker().then((workerAccessor) => {
      if (disposed) {
        return;
      }
      const model = editor.getModel();
      workerAccessor(model.uri).then((worker) => {
        if (disposed) {
          return;
        }
        worker.setSchemaFromShowSchema(
          schema.DatabaseSchema,
          "https://ade.loganalytics.io/subscriptions/2f84d581-f18a-473f-8574-766c505c263d/resourceGroups/soc-defense.fun-qcyber-rg/providers/Microsoft.OperationalInsights/workspaces/soc-defensefun-qcyber-la",
          "soc-defensefun-qcyber-la"
        );
      });
    });

    return () => {
      disposed = true;
      editor.dispose();
    };
  }, [editorRef]);

  return <StyledEditor ref={divRef} />;
});

const StyledEditor = styled.div`
  width: 100%;
  height: 75vh;
`;
