import { ConfirmModal, Modal } from "lib/components/Modal";
import { Table } from "lib/components/Table"
import { Tooltip } from "lib/components/Tooltip";
import { Input } from "lib/components/Input";
import { Select } from "lib/components/Select";
import { getFormValues } from "lib/helpers/getFormValues";
import { fetchData, useFetch, Spinner } from "lib/helpers/fetchData";
import { getApiUrl } from "lib/helpers/getApiUrl";
import { useState, useRef } from "react";
import { CTAButton } from "lib/components/CTAButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { StyledForm } from "lib/styles/general";

const getAuthAttribs = () => fetchData(`${getApiUrl("IDEM_API")}/authorisationAttributes`);
const getEntityAuthAttribs = ({ customerId }) => fetchData(`${getApiUrl("IDEM_API")}/entities/${customerId}/authorisationAttributes`);
const deleteAuthAttrib = ({ id, customerId }) => fetchData(`${getApiUrl("IDEM_API")}/entities/${customerId}/authorisationAttributes/${id}`, null, "DELETE");
const addAuthAttrib = ({ id, customerId }) => fetchData(`${getApiUrl("IDEM_API")}/entities/${customerId}/authorisationAttributes`, JSON.stringify({ attributeId: Number(id) }), "POST");

export const Authorisation = ({ customerId, reload }) => {
  const [addAuthAttribModal, setAddAuthAttribModal] = useState({ showModal: false, callback: null });
  const [confirmModal, setConfirmModal] = useState({ showModal: false, callback: null });

  const { data: entityAuthAttribsData, isLoading: entityAttribsLoading, error: entityAttribsError, reload: reloadEntityAuthAttribs } = useFetch(getEntityAuthAttribs, { customerId });

  const handleAddAuthAttrib = async (id) => {
    await addAuthAttrib({ id, customerId });
    reloadEntityAuthAttribs();
    reload();
  }

  const handleDeleteAuthAttrib = async (id) => {
    await deleteAuthAttrib({ id, customerId });
    reloadEntityAuthAttribs();
    setConfirmModal({ showModal: false });
    reload();
  }

  const handleShowAddAuthAttrib = () => {
    setAddAuthAttribModal({ showModal: true, callback: handleAddAuthAttrib });
  }

  const handleDeleteConfirm = (id) => {
    setConfirmModal({ showModal: true, callback: () => handleDeleteAuthAttrib(id) });
  }

  const renderEntityAuthAttributesTable = () => {
    if (entityAttribsError) return <div style={{ color: "red", margin: "1rem", textAlign: "center", fontSize: "1.5rem" }}>Error fetching authorisation attributes</div>;
    if (entityAttribsLoading) return <Spinner />;

    return (
      <Table>
        <thead>
          <tr>
            <th>Authorisation Attribute</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {entityAuthAttribsData?.length ? entityAuthAttribsData.map((attrib) => (
            <tr key={attrib.id}>
              <td>{attrib.displayName}</td>
              <td>{attrib.description}</td>
              <td style={{ overflow: "visible" }}>
                <Tooltip xHoverPos="left" yHoverPos="center" icon={faTrash} hoverText="Delete" clickAction={() => handleDeleteConfirm(attrib.id)} />
              </td>
            </tr>
          )) : (
            <tr className="no-results-row">
              <td colSpan={4} className="no-results-text">
                No attributes added yet
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      {renderEntityAuthAttributesTable()}
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1rem" }}>
        <CTAButton onClick={handleShowAddAuthAttrib} style={{ width: "10rem" }}>+  Attribute</CTAButton>
      </div>
      <AddAuthAttribModal {...addAuthAttribModal} hide={() => setAddAuthAttribModal({ showModal: false })} />
      <ConfirmModal {...confirmModal} hide={() => setConfirmModal({ showModal: false })} />
    </>
  )
};

export const AddAuthAttribModal = ({ hide, showModal, callback }) => {
  const { data: authAttribs, isLoading: authAttribsLoading, error: authAttribsError } = useFetch(getAuthAttribs, {});
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleHide = () => {
    setError(null);
    setIsLoading(false);
    hide();
  }

  const handleAddAuthAttrib = async () => {
    const { attribute } = getFormValues(formRef.current);
    setIsLoading(true);
    setError(null)

    try {
      await callback(attribute);
    } catch (e) {
      setError(e?.message);
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    handleHide();
  }

  const renderAvailableAuthAttributes = () => {
    if (authAttribsError) return <div style={{ color: "red" }}>Error fetching authorisation attributes</div>;
    if (authAttribsLoading) return <Spinner />;

    return (
      <Input label="Add Attribute" labelStyle={{ margin: 0 }}>
        <Select name="attribute">
          <option value="" disabled>Select Authorisation Attribute</option>
          {authAttribs?.map((attribute) => (
            <option key={attribute.id} value={attribute.id}>{attribute.displayName}</option>
          ))}
        </Select>
      </Input>
    )
  }

  return (
    <Modal showModal={showModal} hide={handleHide} header="Add Authorisation Attribute" width="38rem">
      <StyledForm ref={formRef}>
        {renderAvailableAuthAttributes()}
      </StyledForm>
      {error && <div style={{ color: "red", margin: "1rem" }}>{error}</div>}
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem", gap: "1rem" }}>
        <CTAButton isSecondary onClick={handleHide}>Cancel</CTAButton>
        <CTAButton isLoading={isLoading} onClick={handleAddAuthAttrib}>Save</CTAButton>
      </div>
    </Modal>
  )
}