const API_MAP = {
  ADMIN_API: {
    path: "clarity/api/manage",
    legacy: `${process.env.REACT_APP_ADMIN_API}`,
  },
  BRAIN_API: {
    path: "brain/api",
    legacy: `${process.env.REACT_APP_BRAIN_API}`,
  },
  CLARITY_API: {
    path: "clarity/api",
    legacy: `${process.env.REACT_APP_CLARITY_API}`,
  },
  IDEM_API: {
    path: "idem",
    legacy: `${process.env.REACT_APP_IDEM_API}`,
  },
  JIRA_MANAGEMENT_API: {
    path: "jira-management",
    legacy: `${process.env.REACT_APP_JIRA_MANAGEMENT_API}`,
  },
  PENTEST_API: {
    path: "pentest/api/manage",
    legacy: `${process.env.REACT_APP_PENTEST_API}`,
  },
  PENTEST_API_CLARITY: {
    path: "pentest/api",
    legacy: `${process.env.REACT_APP_PENTEST_API_CLARITY}`,
  },
  PHISH_API: {
    path: "phish/api/manage",
    legacy: `${process.env.REACT_APP_PHISH_API}`,
  },
  PHISH_API_V2: {
    path: "phish-v2/api/manage",
    legacy: `${process.env.REACT_APP_PHISH_API_V2}`,
  },
  REPORTING_API: {
    path: "reporting/api",
    legacy: `${process.env.REACT_APP_REPORTING_API}`,
  },
  SERVICE_DELIVERY_API: {
    path: "service-delivery/api/manage",
    legacy: `${process.env.REACT_APP_SERVICE_DELIVERY_API}`,
  },
  SOC_API: {
    path: "soc/api/manage",
    legacy: `${process.env.REACT_APP_SOC_API}`,
  },
  USER_API: {
    path: "api/user",
    legacy: `${process.env.REACT_APP_USER_API}`,
  },
  VULN_API: {
    path: "vuln/api/manage",
    legacy: `${process.env.REACT_APP_VULN_API}`,
  },
};

export const getApiUrl = (api) => {
  const baseUrl = localStorage.getItem("regionBaseUrl");
  if (API_MAP[api] === undefined) {
    throw new Error(`Invalid key name - ${api}`);
  }
  const apiAddOn = API_MAP[api].path;
  return baseUrl ? `${baseUrl}/${apiAddOn}` : API_MAP[api].legacy;
};
